import { FC, ReactNode } from "react";

import { LabelType } from "@lib/data/schemas/label";

import { LabelDot } from "@components/Labels";
import Listbox, {
  ListboxOption,
  useSimpleSearchProps,
} from "@components/Listbox";

interface LabelPickerProps {
  onLabelChange: (value: LabelType) => void;
  labels: LabelType[];
  placeholder: string | ReactNode;
  value?: LabelType;
}

const LabelPicker: FC<LabelPickerProps> = ({
  onLabelChange,
  labels,
  placeholder,
  value,
}) => {
  const { filteredOptions: filteredLabels, searchProps } = useSimpleSearchProps(
    labels,
    "Search labels..."
  );

  return (
    <div className="shadow-none">
      <Listbox
        searchProps={searchProps}
        customMenuClassNames="!absolute !w-60 !mt-2"
        customListboxClassNames="!shadow-none"
        onChange={onLabelChange}
        label={null}
        placeholder={placeholder}
        value={value}
        customAddButton
        customMenuWrapperClassNames="bg-white"
      >
        <>
          {filteredLabels?.map((label, index) => {
            const { title, color } = label;
            return (
              <ListboxOption key={`label-picker-item-${index}`} value={label}>
                <div className="flex gap-2">
                  <LabelDot color={color} />
                  <div>{title}</div>
                </div>
              </ListboxOption>
            );
          })}
        </>
      </Listbox>
    </div>
  );
};

export default LabelPicker;
