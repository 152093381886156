import usePersistentState from "@hooks/use-persistent-state";

export type PersistentValueType = {
  end?: string;
  start?: string;
  dateRange?: "custom" | "last-90-days" | "last-30-days" | "current";
  memberId?: string;
  packageId?: string;
  labelId?: string;
  outcomeId?: string;
};

export const usePersistentTodaysViewFilter = () => {
  const { persistentValue, persistentSetValue } =
    usePersistentState<PersistentValueType>(
      "todaysViewFilterOptions",
      {},
      true
    );

  return {
    persistentTodaysView: persistentValue,
    setPersistentTodaysView: persistentSetValue,
  };
};
