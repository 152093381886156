import React, { useEffect } from "react";
import { getDashboard } from "api-services/definitions/user";
import { useApiGet } from "api-services/endpoints";
import classNames from "classnames";

import { useAuth } from "@contexts/auth";
import { useBillingRatesContext } from "@contexts/billing-rates";
import { useContactsNumbers } from "@hooks/data/clients";
import { useInvoices } from "@hooks/data/invoices";
import useAccessType from "@hooks/use-access-type";
import { useGetCalendars } from "@hooks/use-get-integrations";
import useOrganization from "@hooks/use-organization";
import { useGetTier } from "@hooks/use-tier";

import { ConditionalBusinessRenderer } from "@components/ConditionalTeamsRenderer";
import AppointmentsDashboardCard from "@components/Home/AppointmentsDashboardCard";
import ClientsDashboardCard from "@components/Home/ClientsDashboardCard";
import PaymentsDashboardCard from "@components/Home/PaymentsDashboardCard";
import ActiveClientsCard from "@components/TodaysView/ActiveClientsCard";
import ActivePackagesCard from "@components/TodaysView/ActivePackagesCard";
import TodaysViewSharedFilters from "@components/TodaysView/TodaysViewSharedFilters";

import SessionBillingDashboardCard from "./SessionBillingDashboardCard";
import StepIndicator from "./StepIndicator";

const DashboardCardsList = () => {
  const { organization, oid, isOwner } = useAuth();
  const tier = useGetTier();
  const canAccessTodaysView = ["teams", "business"].includes(tier);
  const hideTodaysViewCards = tier && !canAccessTodaysView;

  const { hasFullAccess } = useAccessType();
  const { canAccessTheFeature, hasSessionBillingSettings } =
    useBillingRatesContext();
  const { update } = useOrganization(oid);
  const { data, loading: loadingDashboardData } = useApiGet(getDashboard, {
    userId: oid ?? "",
  });

  const { loading: loadingContacts, active, total } = useContactsNumbers();
  const { accounts } = useGetCalendars(!hasFullAccess);
  const clientsNumbers = {
    total: total,
    active: active,
  };
  const { data: invoices, loading: loadingInvoices } = useInvoices();
  const pastSeconds = data?.totalSeconds ?? 0;

  const hasCalendarIntegrated = React.useMemo(
    () => !!accounts?.length,
    [accounts]
  );
  const hasClientAdded = (clientsNumbers.active ?? 0) > 0;
  const hasStripeIntegrated = organization?.stripe?.stripe_user_id;
  const dashboardStep = React.useMemo(() => {
    if (
      hasCalendarIntegrated &&
      hasClientAdded &&
      (hasStripeIntegrated || organization?.dashboard?.payments === "dismissed")
    )
      return 4;
    if (hasCalendarIntegrated && hasClientAdded) return 3;
    if (hasCalendarIntegrated) return 2;
    if (!hasCalendarIntegrated) return 1;
    return 4;
  }, [
    hasCalendarIntegrated,
    hasClientAdded,
    hasStripeIntegrated,
    organization?.dashboard?.payments,
  ]);

  const shouldShowDashboardSteps = isOwner && dashboardStep !== 4;

  const getBillingCardGridClassNames = () => {
    const hasAccessAndBilling =
      canAccessTheFeature && hasSessionBillingSettings;
    if (hasAccessAndBilling && hideTodaysViewCards) return "md:grid-cols-4";
    if (!hasAccessAndBilling && !hideTodaysViewCards) return "md:grid-cols-2";
    return "md:grid-cols-3";
  };

  useEffect(() => {
    if (organization?.stripe?.stripe_user_id)
      update({
        dashboard: {},
        updatedAt: new Date(),
      });
  }, [organization?.stripe?.stripe_user_id]);

  const renderAppointmentCard = (className?: string) =>
    hasFullAccess &&
    hideTodaysViewCards && (
      <AppointmentsDashboardCard
        totalSeconds={pastSeconds}
        loading={loadingDashboardData}
        isStatic={dashboardStep === 1}
        isSelected={dashboardStep === 1}
        className={className}
      />
    );

  const renderClientCard = (className?: string) =>
    hasFullAccess &&
    hideTodaysViewCards && (
      <ClientsDashboardCard
        clientsNumbers={clientsNumbers}
        isLoadingClients={loadingContacts}
        isStatic={[1, 2].includes(dashboardStep)}
        isSelected={dashboardStep === 2}
        className={className}
      />
    );

  const renderPaymentCard = hasFullAccess && hideTodaysViewCards && (
    <PaymentsDashboardCard
      payments={invoices}
      isStatic={[1, 2, 3].includes(dashboardStep)}
      isSelected={dashboardStep === 3}
      isLoading={loadingInvoices || loadingContacts}
    />
  );

  const renderSessionBillingCard = hasSessionBillingSettings && (
    <ConditionalBusinessRenderer>
      <SessionBillingDashboardCard />
    </ConditionalBusinessRenderer>
  );

  const renderActivePackagesCard = (
    <ConditionalBusinessRenderer>
      <ActivePackagesCard />
    </ConditionalBusinessRenderer>
  );

  const renderActiveClientsCard = (
    <ConditionalBusinessRenderer>
      <ActiveClientsCard />
    </ConditionalBusinessRenderer>
  );

  return (
    <>
      <div className="flex items-center justify-between">
        <h2 className="text-black-ink text-xl font-regular">
          {
            {
              1: "You’re nearly there!",
              2: "Two more steps to go",
              3: "Almost there, one last step",
              4: "Activity dashboard",
            }[isOwner ? dashboardStep : 4]
          }
        </h2>
        {dashboardStep !== 4 ? (
          <StepIndicator step={dashboardStep} />
        ) : (
          <ConditionalBusinessRenderer>
            <TodaysViewSharedFilters />
          </ConditionalBusinessRenderer>
        )}
      </div>
      {shouldShowDashboardSteps && (
        <p className="text-grey-500 text-sm leading-5">
          Follow these 3 steps to get your Practice set up.
        </p>
      )}
      {organization?.dashboard?.payments !== "dismissed" ||
      organization?.stripe?.stripe_user_id ? (
        <div className="my-6">
          <div
            className={classNames(
              "grid gap-3 sm:grid-cols-2",
              getBillingCardGridClassNames()
            )}
          >
            {renderAppointmentCard()}
            {renderPaymentCard}
            {renderActivePackagesCard}
            {renderActiveClientsCard}
            {renderClientCard()}
            {renderSessionBillingCard}
          </div>
        </div>
      ) : (
        <div className="flex flex-col sm:flex-row gap-4 my-6">
          <div className="grid gap-4 sm:flex-1 md:flex">
            {renderAppointmentCard("w-full")}
            {renderClientCard("w-full")}
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardCardsList;
