import React, { createContext, ReactNode, useState } from "react";
import { noop } from "lodash";
import { useSWRConfig } from "swr";

import {
  useGetTodaysViewActiveClients,
  useGetTodaysViewActivePackages,
  useGetTodaysViewSessionsByOutcome,
} from "@hooks/use-todays-view";

import { usePersistentTodaysViewFilter } from "./utils";

// todo - add types after WEB 10525
interface TodaysViewFiltersContextValue {
  filters: any;
  setFilters: (filters: any) => void;
  refresh: () => void;
}

export const TodaysViewFilterContext =
  createContext<TodaysViewFiltersContextValue>({
    filters: {
      memberId: undefined,
      start: undefined,
      end: undefined,
      packageId: undefined,
      labelId: undefined,
      outcomeId: undefined,
    },
    setFilters: noop,
    refresh: noop,
  });

export const useTodaysViewFiltersContext = () => {
  return React.useContext(TodaysViewFilterContext);
};

export const TodaysViewFilterProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  return <InnerContext>{children}</InnerContext>;
};

const useGetter = (hook) => {
  const { filters } = useTodaysViewFiltersContext();
  return hook(filters);
};

export const useFetchTodaysViewActivePackages = () => {
  return useGetter(useGetTodaysViewActivePackages);
};

export const useFetchTodaysViewActiveClients = () => {
  return useGetter(useGetTodaysViewActiveClients);
};

export const useFetchTodaysViewSessionsByOutcome = () => {
  return useGetter(useGetTodaysViewSessionsByOutcome);
};

export const InnerContext: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { mutate, cache } = useSWRConfig();

  const { persistentTodaysView, setPersistentTodaysView } =
    usePersistentTodaysViewFilter();
  const { memberId, start, end, dateRange, packageId, labelId, outcomeId } =
    persistentTodaysView ?? {};

  const [filters, setFilters] = useState<any>({
    ...(memberId && { memberId }),
    ...(start && { start }),
    ...(end && { end }),
    ...(dateRange && { dateRange }),
    ...(packageId && { packageId }),
    ...(labelId && { labelId }),
    ...(outcomeId && { outcomeId }),
  });

  const handleChangeFilter = (newFilters: any) => {
    setPersistentTodaysView({
      ...persistentTodaysView,
      ...newFilters,
    });
    setFilters({
      ...filters,
      ...newFilters,
    });
  };

  const refresh = () => {
    const promises: Promise<void>[] = [];
    for (const key of cache.keys()) {
      if (key.includes("/todays-view")) {
        promises.push(mutate(key));
      }
    }
    return Promise.all(promises);
  };

  const value: TodaysViewFiltersContextValue = {
    filters,
    setFilters: handleChangeFilter,
    refresh,
  };

  return (
    <TodaysViewFilterContext.Provider value={value}>
      {children}
    </TodaysViewFilterContext.Provider>
  );
};
