import { FC } from "react";

import useLabels from "@hooks/use-labels";
import { LabelType } from "@lib/data/schemas/label";

import DropdownIcon from "@components/Icons/DropdownIcon";
import LabelIcon from "@components/Icons/LabelIcon";
import { LabelDot } from "@components/Labels";
import LabelPicker from "@components/Labels/LabelPicker";

import DefaultFilterDescriptor from "./DefaultFilterDescriptor";
import TodaysViewCard from "./TodaysViewCard";
import {
  useFetchTodaysViewActiveClients,
  useTodaysViewFiltersContext,
} from "./TodaysViewContext";

const ActiveClientsCard: FC = () => {
  const { data: response, isLoading } = useFetchTodaysViewActiveClients();
  const { filters, setFilters } = useTodaysViewFiltersContext();

  const labelId = filters?.labelId;
  const { labels } = useLabels();
  const label = labels?.find((label) => label.id === labelId);
  const hasLabels = labels?.length && labels?.length > 0;

  const metricData = response?.data;
  const metricPoint = metricData?.metricPoint;

  const onLabelChange = (value: LabelType) => {
    setFilters({ labelId: value.id });
  };

  // blocked by 10726
  // const onClick = () => {
  //   router.push({
  //     pathname: "/contacts",
  //     query: { labelId },
  //   });
  // };

  const filterDescription =
    (metricPoint || metricPoint === 0) && label ? (
      <div className="flex gap-2 items-center justify-center w-full truncate">
        <LabelDot color={label.color} />
        <div className="font-medium truncate">{label.title}</div>
      </div>
    ) : (
      <DefaultFilterDescriptor Icon={LabelIcon} type="labels" />
    );

  const customFilter = hasLabels ? (
    <LabelPicker
      onLabelChange={onLabelChange}
      placeholder={
        <div className="rounded-md text-sm font-medium bg-grey-950 flex items-center border hover:cursor-pointer">
          <DropdownIcon className="inline w-5 h-5 text-grey-500" />
        </div>
      }
      value={label}
      labels={labels}
    />
  ) : null;

  return (
    <TodaysViewCard
      title="Active clients"
      metricData={metricData}
      tooltipCopy="Clients that meet the label filter and are not archived."
      filterDescription={filterDescription}
      customFilter={customFilter}
      isLoading={isLoading}
      // onClick={label ? onClick : undefined}
    />
  );
};

export default ActiveClientsCard;
