import { FC, ReactNode } from "react";
import { TodaysView } from "@practice/sdk";
import classNames from "classnames";

import { momentDate } from "@lib/utils/todos";

import ArrowIcon from "@components/Icons/ArrowIcon";
import InfoIcon from "@components/Icons/InfoIcon";
import LoadingSpinner from "@components/LoadingSpinner";
import { Tooltip } from "@components/Tooltips/Tooltip";

import { useTodaysViewFiltersContext } from "../TodaysViewContext";

interface TodaysViewCardProps {
  title: string;
  tooltipCopy: string;
  filterDescription: ReactNode;
  isLoading: boolean;
  metricData?: TodaysView;
  className?: string;
  customFilter?: ReactNode;
  onClick?: () => void;
  totalClassName?: string;
}

const TodaysViewCard: FC<TodaysViewCardProps> = ({
  title,
  tooltipCopy,
  filterDescription,
  metricData,
  className,
  customFilter,
  isLoading,
  onClick,
  totalClassName,
}) => {
  const {
    total,
    metricPoint,
    metricChange,
    metricIsPositive,
    metricIsPercentage,
  } = metricData ?? {};

  const { filters } = useTodaysViewFiltersContext();
  const { start, end } = filters;

  const differenceInDate =
    start && end ? momentDate(end).diff(momentDate(start), "days") : undefined;

  const renderMetricChange = metricChange && (
    <div
      className={classNames(
        "flex items-center pr-1.5 rounded-md mt-1",
        metricIsPositive ? "bg-action-600" : "bg-grey-500 text-white"
      )}
    >
      <ArrowIcon
        className={classNames(
          "transform -scale-x-1",
          metricIsPositive ? "-rotate-45" : "rotate-45"
        )}
      />
      <span className="font-medium">
        {metricIsPositive ? "+" : "-"}
        {metricChange}
        {metricIsPercentage ? "%" : ""}
      </span>
    </div>
  );

  return (
    <div
      className={classNames(
        className,
        "border rounded-2xl p-4 pt-2.5 border-grey-900 bg-grey-950 text-sm h-full",
        onClick && "hover:cursor-pointer hover:bg-action-950"
      )}
    >
      <div className="flex flex-col items-center justify-between">
        <div className="grid grid-cols-[1fr_22px] w-full">
          <div
            className={classNames(
              "flex items-center gap-1 overflow-hidden mr-2",
              !customFilter && "pt-1.5"
            )}
          >
            <h1 className="leading-5 text-grey-500 truncate">{title}</h1>
            <Tooltip trigger={<InfoIcon className="w-5 h-5 text-grey-500" />}>
              {tooltipCopy}
            </Tooltip>
          </div>
          {customFilter}
        </div>
        <div
          className="flex items-center justify-center flex-col sm:h-32 lg:h-40 w-full pb-4"
          onClick={onClick}
        >
          {isLoading ? (
            <LoadingSpinner variant="transparent" />
          ) : (
            <>
              {metricPoint || metricPoint === 0 ? (
                <div className="flex items-center gap-1">
                  <span className="text-3xl">{metricPoint}</span>
                  <span className="text-grey-500 text-base">/{total}</span>
                </div>
              ) : (
                <div className={classNames("text-3xl", totalClassName)}>
                  {total ?? 0}
                </div>
              )}
              <h3 className="w-full">{filterDescription}</h3>
              {differenceInDate ? (
                <Tooltip trigger={renderMetricChange}>
                  Compared to the previous {differenceInDate} days
                </Tooltip>
              ) : (
                renderMetricChange
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TodaysViewCard;
